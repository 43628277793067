<template>
  <action-dispatcher action="courses/getAll">
    <div class="view-formations py-5 container-fluid">
      <h5 class="mb-4">Formação em inglês</h5>

      <div class="row">
        <div
          v-for="(course, i) in courses"
          :key="i"
          class="col-lg-3 col-md-4 col-sm-6 mb-4 d-flex"
        >
          <course-card class="flex-fill" :course="course" />
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import CourseCard from "@/components/home/CourseCard";

export default {
  components: { CourseCard },
  computed: {
    ...mapState("courses", ["courses"])
  }
};
</script>
